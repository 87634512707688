/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import ComponentCard from '../../../components/ComponentCard';
import api from '../../../constants/api';
import Swal from 'sweetalert2';

export default function Brands() {
  const [data, setData] = useState({
    name: '',
    type: '',
    operation: 'insert',
    id: null, // For updating
  });
  const [oilBrands, setOilBrands] = useState([]);
  const [batteryBrands, setBatteryBrands] = useState([]);
  const [tyreBrands, setTyreBrands] = useState([]);

  const [activeTab, setActiveTab] = useState('1');

  const handleInputs = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getAllBrandsForOil = (obj) => {
    api.post('/store/addUpdateOilAndBatteryBrand', obj).then((res) => {
      if (obj.type === '0') {
        setOilBrands(res.data.data);
        getAllBrandsForOil({ operation: 'fetch', type: '1' });
      } else if (obj.type == '2') {
        setBatteryBrands(res.data.data);
      } else {
        setBatteryBrands(res.data.data);
        getAllBrandsForOil({ operation: 'fetch', type: '2' });
      }
    });
  };

  const addUpdateOilAndBatteryBrand = () => {
    api.post('/store/addUpdateOilAndBatteryBrand', data).then((res) => {
      if (data.operation === 'insert') {
        alert('Brand added successfully');
      } else {
        alert('Brand updated successfully');
      }
      // Refresh the brand lists after adding/updating
      getAllBrandsForOil({ operation: 'fetch', type: '0' });
      setData({ name: '', type: '', operation: 'insert', id: null });
    });
  };

  const editBrand = (brand) => {
    setData({
      name: brand.ob_name,
      type: brand.type,
      operation: 'update',
      id: brand.ob_id,
    });
  };

  useEffect(() => {
    getAllBrandsForOil({ operation: 'fetch', type: '0' });
  }, []);
  const deleteCar = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post('/store/addUpdateOilAndBatteryBrand', { operation: 'delete', id })
          .then((res) => {
            if (res.data.status === '200') {
              window.location.reload();
            }
          })
          .catch(() => {
            Swal.fire('Error', 'Cannot Delete Record', 'error');
          });
      }
    });
  };
  return (
    <div>
      <ComponentCard>
        <Row>
          <Col lg={3}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                onChange={handleInputs}
                value={data.name}
                className="form-control"
                type="text"
                name="name"
              />
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Label>Type</Label>
              <select
                className="form-control"
                name="type"
                value={data.type}
                onChange={handleInputs}
              >
                <option value="">-- Select --</option>
                <option value="0">Oil</option>
                <option value="1">Battery</option>
                <option value="2">Tyre</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg={3}>
            <Button color="dark" size="sm" onClick={addUpdateOilAndBatteryBrand}>
              {data.operation === 'insert' ? 'Add Brand' : 'Update Brand'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Nav pills className="custom-pills mt-4">
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active text-dark-white' : 'cursor-pointer'}
                onClick={() => {
                  setActiveTab('1');
                }}
              >
                Oil
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active text-dark-white' : 'cursor-pointer'}
                onClick={() => {
                  setActiveTab('2');
                }}
              >
                Batteries
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '3' ? 'active text-dark-white' : 'cursor-pointer'}
                onClick={() => {
                  setActiveTab('3');
                }}
              >
                Tyres
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="mt-3">
            <TabPane tabId="1">
              <Table>
                <thead>
                  <tr>
                    <td>S.no</td>
                    <td>Name</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {oilBrands &&
                    oilBrands.map((ob, i) => (
                      <tr key={ob.id}>
                        <td>{i + 1}</td>
                        <td>{ob.ob_name}</td>
                        <td>
                          <Button color="danger" size="sm" onClick={() => deleteCar(ob.ob_id)}>
                            Delete
                          </Button>
                          <Button size="sm" onClick={() => editBrand(ob)}>
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TabPane>
            <TabPane tabId="2">
              <Table>
                <thead>
                  <tr>
                    <td>S.no</td>
                    <td>Name</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {batteryBrands &&
                    batteryBrands.map((ob, i) => (
                      <tr key={ob.id}>
                        <td>{i + 1}</td>
                        <td>{ob.ob_name}</td>
                        <td>
                          <Button color="danger" size="sm" onClick={() => deleteCar(ob.ob_id)}>
                            Delete
                          </Button>
                          <Button size="sm" onClick={() => editBrand(ob)}>
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TabPane>
            <TabPane tabId="3">
              <Table>
                <thead>
                  <tr>
                    <td>S.no</td>
                    <td>Name</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {batteryBrands &&
                    batteryBrands.map((ob, i) => (
                      <tr key={ob.id}>
                        <td>{i + 1}</td>
                        <td>{ob.ob_name}</td>
                        <td>
                          <Button color="danger" size="sm" onClick={() => deleteCar(ob.ob_id)}>
                            Delete
                          </Button>
                          <Button size="sm" onClick={() => editBrand(ob)}>
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </Row>
      </ComponentCard>
    </div>
  );
}
